@import url(https://fonts.googleapis.com/css2?family=Raleway&display=swap);
.countdown-time[data-v-c53bc3e4]{font-size:2.5em;font-weight:bold}.countdown-time small[data-v-c53bc3e4]{font-size:.35em}.countdown-time .colon[data-v-c53bc3e4]{min-width:20px;text-align:center}


.container .green-bg[data-v-9a4feb84]{background:#4bad62}.container .blue-bg[data-v-9a4feb84]{background:#0271be}.container .grey-bg[data-v-9a4feb84]{background:#d0d0d1}.container .big-numbers[data-v-9a4feb84]{color:#4bad62;font-size:2.5em;font-weight:bold}.container .big-numbers small[data-v-9a4feb84]{color:#737373;font-size:14px}.container .progess-table[data-v-9a4feb84]{font-size:9px}.container table.w-bars[data-v-9a4feb84]{font-size:9px !important}.container .total-reviews[data-v-9a4feb84]{margin-top:-8px;font-size:.9em}.container .card[data-v-9a4feb84]{min-height:140px}.container .rank-table tbody tr[data-v-9a4feb84]{background:#e1e3e6;color:#737373}.container .rank-table tbody tr[data-v-9a4feb84]:nth-child(1){background:#0271be;color:#fff}.container .rank-table tbody tr[data-v-9a4feb84]:nth-child(2){background:#bc1823;color:#fff}.container .rank-table tbody tr[data-v-9a4feb84]:nth-child(3){background:#fff}.container .rank-table tbody tr.active[data-v-9a4feb84]{display:table-row}.container #employee-load[data-v-9a4feb84]{border-radius:0;margin-bottom:20px}.container .powered-by[data-v-9a4feb84]{bottom:0;left:0;width:100%;padding-left:0;text-align:center}.container .blue-bg.position-relative[data-v-9a4feb84]{padding-bottom:50px}.main-leaderboard[data-v-9a4feb84]{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;margin-bottom:0 !important}

body{
  padding-bottom:0;
}
@media screen and (max-width:640px) {
body {
    background: #fff;
}
}


/*# sourceMappingURL=chunk-8e0534b2.e42e7d81.css.map*/
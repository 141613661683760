






























































































































































































































































































































.container{
  .green-bg{
    background: #4bad62;
  }
  .blue-bg{
    background: #0271be
  }
  .grey-bg{
    background: #d0d0d1;
  }
  .big-numbers{
    color: #4bad62;
    font-size: 2.5em;
    font-weight: bold;
    small{
      color: #737373;
      font-size: 14px;
    }
  }
  .progess-table{
    font-size: 9px;
  }
  table.w-bars{
    font-size: 9px !important;
  }
  .total-reviews{
    margin-top: -8px;
    font-size: .9em;
  }
  .card{
    min-height: 140px;
  }
  .rank-table{
    tbody{
      tr{
        background: #e1e3e6;
        color: #737373;
        /*display: none;*/
      }
      tr:nth-child(1){
        background: #0271be;
        color: #fff;
      }
      tr:nth-child(2){
        background: #bc1823;
        color: #fff;
      }
      tr:nth-child(3){
        background: #fff;
      }
      tr.active{
        display: table-row;
      }
    }
  }
  #employee-load{border-radius: 0;margin-bottom:20px}
  .powered-by{
    bottom: 0;
    left: 0;
    width: 100%;
    padding-left: 0;
    text-align: center;
  }
  .blue-bg.position-relative{
    padding-bottom: 50px;
  }
}
.main-leaderboard{
  display: flex;
  align-items: center;
  margin-bottom: 0 !important;
}
